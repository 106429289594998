<template>
  <div>
    <div style="padding: 70px 0" class="sctp-tc">
      <template v-if="orderInfo">
        <template v-if="orderInfo.status === 5">
          <div class="">
            <div class="sctp-mar-b15">
              <span class="sctp-mar-r5">确认收到商家服务，同意支付托管金额。</span>
            </div>
            <el-button @click="confirmPay" type="primary">同意支付</el-button>
          </div>
        </template>
        <template v-if="orderInfo.status === 6">
          <i class="el-icon-success"></i>
          <div class="sctp-color-main">
            <div class="sctp-mar-b15">
              <span class="sctp-mar-r5">系统已将您托管的资金打到了服务商的账号</span>
            </div>
            <router-link :to='`/service/buy/evaluate?orderId=${orderId}`'>
              <el-button type="primary">去评价</el-button>
            </router-link>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {service} from "@/apis/index";

export default {
  name: "pay.vue",
  data() {
    return {
      orderId: null,
      orderInfo: null,
    }
  },
  methods: {
    orderDetail() {
      service.orderDetail({
        orderId: this.orderId
      }).then(res => {
        let {retdata} = res;
        this.orderInfo = retdata;
      });
    },
      confirmPay() {
      if (!this.orderInfo || this.orderInfo.status !== 5) {
        return;
      }
      this.$confirm('确认支付!', '提示', {}).then(res => {
        service.confirmPayHosting({
          orderId: this.orderId,
          userId: this.user.userId
        }).then(res => {
          this.orderDetail();
        })
      })
    }
  },
  beforeMount() {
    let {query: {orderId}} = this.$route;
    if (orderId) {
      this.orderId = orderId
      this.orderDetail();
    } else {
      this.goPage('/');
    }
  }
}
</script>

<style scoped>
.el-icon-success {
  font-size: 100px;
  color: #F90;
  margin-bottom: 15px;
}
</style>
